import React from 'react';
import './newsletter.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Newsletter extends React.Component {

    state = {
        name: '',
        email: '',
        type: '',
        result: ''
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;

        this.setState({
            [name]: value,
        })
    };

    handleSubmit = async event => {
        event.preventDefault();
        let result = '';
        switch (this.state.type) {
            case 'company':
                window.location.replace(`https://fairtrade.us5.list-manage.com/subscribe?u=e570fa58a8fe0101393c49e72&id=f5f02f3568&MERGE0=${this.state.email}&MERGE1=${this.state.name}`);
                break;
            case 'press':
                window.location.replace(`https://us5.list-manage.com/subscribe?u=e570fa58a8fe0101393c49e72&id=7372ee792f&MERGE0=${this.state.email}&MERGE9=${this.state.name}`);
            case 'customer':
            default:
                window.location.replace(`https://fairtrade.us5.list-manage.com/subscribe?u=e570fa58a8fe0101393c49e72&id=07170f2ae1&MERGE0=${this.state.email}&MERGE9=${this.state.name}`);

                /*result = await addToMailchimp(this.state.email, {
                    NOME: this.state.name
                }, 'https://fairtrade.us5.list-manage.com/subscribe?u=e570fa58a8fe0101393c49e72&id=07170f2ae1');

                 */
        }
        console.log(result);
        this.setState({...this.state, result});
    };

    render() {
        return (
            <section className={'newsletter'}>
                <form onSubmit={this.handleSubmit}>
                    <div className={'box newsletter'}>
                        {this.state.result}
                        <h2>Iscriviti alla <span className="blue">newsletter</span></h2>
                        <p>Iscriviti alla nostra newsletter per ricevere ogni mese le storie, i volti e le testimonianze
                            di chi, dall'altra parte del mondo, coltiva il cibo che amiamo
                        </p>
                        <label>
                            <span>Nome</span>
                            <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                            />
                        </label>
                        <label>
                            <span>Indirizzo email</span>
                            <input
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                required={true}
                            />
                        </label>

                        <label>
                            <span>Sono un...</span>
                            <ul onChange={this.handleInputChange} className={'newsletter-target-type'}>
                                <li>
                                    <input type="radio" value="customer" name="type"/> Consumatore responsabile
                                </li>
                                <li>
                                    <input type="radio" value="company" name="type"/> Azienda
                                </li>
                                <li>
                                    <input type="radio" value="press" name="type"/> Giornalista
                                </li>
                            </ul>
                        </label>
                        <button type="submit">Iscriviti e rimani aggiornato</button>
                    </div>

                </form>
            </section>
        )
    };
}