import React from "react";
import './last-news.scss';
import {StaticImage} from "gatsby-plugin-image";
import arrowRightBlue from "../../images/icons/arrow-right_blue.svg";

export default function LastNews() {
    return (
        <section className={'last-news'}>

            <h2>The future is fair</h2>

            <iframe className={'last-news-video'} src="https://www.youtube.com/embed/PuaAtXacXQk?si=blPV7Lh88mo4bnUr"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            {/*<h2>Contadini: la loro battaglia contro la crisi globale.</h2>*/}
            {/*<div className={'teaser-image'}>*/}
            {/*    <StaticImage*/}
            {/*        src="../../images/Farmers documentari pandemia.jpg"*/}
            {/*        alt="Contadini: la loro battaglia contro la crisi globale."*/}
            {/*        placeholder="blurred"*/}
            {/*        width={800}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className={'teaser-text'}>*/}
            {/*    <p>*/}
            {/*        Vieni a conoscere Ponish, Caroline e Roberto e le sfide, le paure e le speranze di tre contadini che*/}
            {/*        non si arrendono e si battono per un futuro equo.*/}
            {/*    </p>*/}

            {/*    <a href={'https://farmers-documentary.fairtrade.net/it/'} target={'_blank'}>*/}
            {/*        Continua a leggere*/}
            {/*        <img src={arrowRightBlue} alt="Il nostro impegno per la lotta contro il virus" />*/}
            {/*    </a>*/}
            {/*</div>*/}
        </section>
    )
}