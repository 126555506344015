import React, {useRef} from "react";
import logo from '../../images/fairtrade-italia-logotype.svg';
import logoCocoa from '../../images/fairtrade-cocoa.png';
import './navigation.scss';
import Modal from "../modal/modal";
import WhatIs from "../what-is/what-is";
export default function Navigation() {
    const modalRef1 = useRef();
    return (
        <section className={'navigation'}>
            <div className={'logo'}>
                <img src={logo} alt="Fairtrade Italia"/>
                <img src={logoCocoa} alt="Fairtrade Italia - Cocoa" className={'cocoa'}/>
            </div>
            <nav className="menu">
                <ul>
                    <li>
                        <a
                            onClick={() => modalRef1.current.openModal()}
                        >
                            Cosa sono le Settimane Fairtrade
                        </a>
                        <Modal ref={modalRef1}>
                            <WhatIs />
                        </Modal>
                    </li>
                </ul>
            </nav>
        </section>
    )
}
