import React from "react";
import './partners.scss';
import {StaticImage} from "gatsby-plugin-image";
import vegetables from "../../images/icons/vegetables.svg";
import rice from "../../images/icons/rice.svg";
import thankYou from "../../images/icons/thankyou.svg";
import carrerfourJPG from '../../images/partners/Carrefour_Express-Settimane Fairtrade 2022.jpg';
// import carrerfourPDF from '../../images/partners/Carrefour_Settimane_Fairtrade_2021.pdf';
// import carrerfourPDF from '../../images/partners/Carrefour-Iper_Express_2023.pdf';
import carrerfourPDF from '../../images/partners/CARREFOUR_Express2024.pdf';
// import aldiPDF from '../../images/partners/ALDI_Volantino 11.10.2021.pdf';
// import aldiPDF from '../../images/partners/ALDI_Volantino 18.10.2021.pdf';
// import aldiJpg from '../../images/partners/aldi_4.10.22.jpeg';
// import aldiPDF from '../../images/partners/ALDI_Rose_Settimane Fairtrade 2023.pdf';
// import aldiPDF from '../../images/partners/ALDI 9-16.10.23.pdf';
// import aldiPDF from '../../images/partners/Volantino ALDI dal 7 ottobre 2024.pdf';
import aldiPDF from '../../images/partners/ALDI_Offerte_da_lunedi_14_Ottobre.pdf';

// import lidlJpg from '../../images/partners/LIDL-dal-18-10-al-24-10.jpg';
import PAMJpg from '../../images/partners/pam_2022.jpeg';
// import lidlPdf from '../../images/partners/LIDL_Settimane Fairtrade 2022.pdf';
// import lidlPdf from '../../images/partners/LIDL_Settimane Fairtrade 2022.jpeg';
// import lidlPdf from '../../images/partners/Lidl_Settimane Fairtrade 2023.pdf';
// import lidlPdf from '../../images/partners/LIDL_Offerte-valide-dal-07-10-al-13-10.pdf';
import lidlPdf from '../../images/partners/LIDL_21_ottobre_2024.pdf';

import dokPdf from '../../images/partners/DOK_Offerte-valide-dal-02-10-al-08-10.pdf';
import aeoPdf from '../../images/partners/A&O_Offerte-valide-dal-02-10-al-08-10.pdf';
import insPdf from '../../images/partners/INS Volantino Settimane Fairtrade 2024.pdf';
// import coopJpg from '../../images/partners/Coop_Settimane Fairtrade 2023.jpg';
import coopJpg from '../../images/partners/Coop_Settimane_Fairtrade_2023.jpg';
// import coopPdf from '../../images/partners/Volantino UNICOOP TIRRENO dal 3 ottobre 2024.pdf';
import coopPdf from '../../images/partners/Coop Alleanza 3.0-Novacoop-Coop Liguria-Coop Lombardia.pdf';
import insJpg from '../../images/partners/INS_Settimane_Fairtrade_2023.jpg';
import pamJpg from '../../images/partners/PAM_Settimane_Fairtrade_2023.jpg';
import pamPdf from '../../images/partners/Pam Panorama.pdf';
import mercatoPdf from '../../images/partners/MERCATO14OTTOBRE2024.pdf';
import familiaPdf from '../../images/partners/Famila2024.pdf';

export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
}

export default function Partners() {
    const [isOn, toggleIsOn] = useToggle();
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <section className={'partners'} id={'partners'}>
            <h3>Visita i punti vendita e gli e-commerce che hanno aderito</h3>
            <p className={'partners-intro'}>
                A ottobre fare la <strong>'spesa giusta'</strong> è ancora più conveniente, grazie alla
                campagna <strong>Settimane Fairtrade</strong><br/>
                in collaborazione con:
            </p>
            <ul className={'partners-list'}>

               <li onClick={() => openInNewTab(aeoPdf)} className={'pointer'}> 
                <div className={'discount-rate'}></div>
                    <div className={'aeo'}>
                        <StaticImage
                            src="../../images/partners/A&O_logo_nuovo_2012supermercati.jpg"
                            alt="A&O"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>A&O</span> 
                        {/* <a href={aeoPdf} target={'_blank'}>A&O</a> */}
                    </div>
                </li>

                {/*<li onClick={() => openInNewTab(aldiJpg)} >*/}
                
                <li onClick={() => openInNewTab(aldiPDF)} className={'pointer'}>
                <div className={'discount-rate'}></div>
                    <div className={'aldi'}>
                        <StaticImage
                            src="../../images/partners/aldi-logo.png"
                            alt="Aldi"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>ALDI</span> 
                        {/* <a href={aldiPDF} target={'_blank'}>ALDI</a> */}
                    </div>
                </li>     
                <li onClick={() => openInNewTab(carrerfourPDF)} className={'pointer'}>
                    <div className={'discount-rate'}></div>
                    <div className={'carrerfour'}>
                        <StaticImage
                            src="../../images/partners/carrefour-logo.png"
                            alt="Carrefour"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>CARREFOUR</span>    
                        {/*<a href={carrerfourPDF} target={'_blank'} download={'Carrerfour per Settimane Fairtrade'}>CARREFOUR</a> */}
                        {/* <a href={carrerfourPDF} target={'_blank'}>CARREFOUR</a> */}
                    </div>
                </li>

                <li onClick={() => openInNewTab(coopPdf)} className={'pointer'}>
                    <div className={'discount-rate'}></div>
                    <div className={'coop'}>
                        <StaticImage
                            src="../../images/partners/coop-logo.png"
                            alt="COOP"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>COOP</span>   
                        {/* <a href={coopPdf} target={'_blank'}>COOP</a> */}
                    </div>
                </li>

                {/*<li onClick={() => openInNewTab(aldiJpg)} >*/}
                <li onClick={() => openInNewTab(dokPdf)} className={'pointer'}>
                <div className={'discount-rate'}></div>
                    <div className={'dok'}>
                        <StaticImage
                            src="../../images/partners/logodok.jpg"
                            alt="DOK"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>DOK</span>   
                        {/* <a href={dokPdf} target={'_blank'}>DOK</a> */}
                    </div>
                </li>

                {/*<li onClick={() => openInNewTab(aldiJpg)} >*/}
              <li onClick={() => openInNewTab(familiaPdf)} className={'pointer'}>
                <div className={'discount-rate'}></div>
                    <div className={'familia'}>
                        <StaticImage
                            src="../../images/partners/LOGO famila superstore.jpg"
                            alt="Famila"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>Famila</span> 
                        {/* <a href={familiaPdf} target={'_blank'}>Famila</a> */}
                    </div>
                </li>

               <li onClick={() => openInNewTab(insPdf)} className={'pointer'}>
               {/*  <li>*/}
                    <div className={'discount-rate'}></div>
                    <div className={'ins'}>
                            <StaticImage
                                src="../../images/partners/INS-logo.png"
                                alt="INS"
                                placeholder="blurred"
                            />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>INS</span>
                        {/* <a href={insPdf} target={'_blank'}>INS</a>  */}
                    </div>
                </li>

                <li onClick={() => openInNewTab(lidlPdf)} className={'pointer'}>
                    <div className={'discount-rate'}></div>
                    <div className={'lidl'}>
                        {/*<a href={lidlPdf} target={'_blank'}>*/}
                        <StaticImage
                            src="../../images/partners/lidl-logo.png"
                            alt="Lidl"
                            placeholder="blurred"
                        />
                        {/*</a>*/}
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>LIDL</span>
                        {/* <a href={lidlPdf} target={'_blank'}>LIDL</a> */}
                        {/*<span className={'lidl-link'}>LIDL</span>*/}
                    </div>
                </li>

                <li onClick={() => openInNewTab(mercatoPdf)} className={'pointer'}>
                    <div className={'discount-rate'}></div>
                    <div className={'mercato'}>
                        <StaticImage
                            src="../../images/partners/mercato-logo.png"
                            alt="Mercatò"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in</p>
                        <span>MERCATO'</span>
                        {/* <a href={mercatoPdf} target={'_blank'}>MERCATO'</a> */}
                </div>

                </li>
                <li onClick={() => openInNewTab(pamPdf)} className={'pointer'}>
                    <div className={'discount-rate'}></div>
                    <div className={'pam'}>
                        <StaticImage
                            src="../../images/partners/pam-logo.png"
                            alt="PAM"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti in </p>
                        <span>PAM</span>
                        {/* <a href={pamJpg} target={'_blank'}>PAM</a> */}
                    </div>
                </li>
            </ul>
            
            <ul className={'partners-brand-list'}>

                <li onClick={() => openInNewTab('https://www.alcenero.com/collections/tutti-i-prodotti')} >
                    <div className={'discount-rate'}></div>
                    <div className={'alcenero'}>
                        <StaticImage
                            src="../../images/partners/ALCENERO.jpg"
                            alt="ALCE NERO"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti di</p>
                        <span>ALCE NERO</span>
                    </div>
                </li>
                <li onClick={() => openInNewTab('https://shop.costadoro.it/')} >
                    <div className={'discount-rate'}></div>
                    <div className={'costadoro'}>
                        <StaticImage
                            src="../../images/partners/COSTADORO.jpg"
                            alt="COSTADORO"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti di</p>
                        <span>COSTADORO</span>
                    </div>
                </li>
                <li onClick={() => openInNewTab('https://damianorganic.it/')} >
                    <div className={'discount-rate'}></div>
                    <div className={'damiano'}>
                        <StaticImage
                            src="../../images/partners/DAMIANO.jpg"
                            alt="DAMIANO"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti di</p>
                        <span>DAMIANO</span>
                    </div>
                </li>
                <li onClick={() => openInNewTab('https://www.naturalnostos.com/negozio/')} >
                    <div className={'discount-rate'}></div>
                    <div className={'nostos'}>
                        <StaticImage
                            src="../../images/partners/NOSTOS.jpg"
                            alt="NOSTOS"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti di</p>
                        <span>NOSTOS</span>
                    </div>
                </li>
            </ul>
            <div className={'closure'}>
                <p>Cerca i prodotti certificati Fairtrade nel sito o nel volantino e recati nei loro punti vendita: </p>
                <ul>
                    <li>
                        è un'<strong>occasione per provarli</strong>, se non li hai mai acquistati prima <br/>
                        <em>
                            OPPURE
                        </em>
                    </li>
                    <li>
                        per <strong>fare scorta</strong> dei tuoi prodotti preferiti.
                    </li>
                </ul>
            </div>

            {isOn ?
                <p className={'thank-you'}>Grazie del tuo contributo! <img src={thankYou}
                                                                           alt="Fairtrade Italia - Grazie del tuo contributo!"/>
                </p>
                :
                <button onClick={toggleIsOn}>
                    andrò a fare la spesa durante le Settimane Fairtrade
                </button>
            }


            <div className={'overlay-cart-vegetables'}>
                <img src={vegetables} alt="Fairtrade Italia - vegetables" width={'191'} height={'260'} />
            </div>
            <div className={'overlay-cart-rice'}>
                <img src={rice} alt="Fairtrade Italia - black rice" width={'210'} height={'239'} />
            </div>
        </section>
    )
}