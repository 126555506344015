import React from "react";
import './what-is.scss';

export default function WhatIs() {
    return (
        <div className={'what-is'}>
            <h2>Che cosa sono le Settimane Fairtrade?</h2>

            <div className="text-container">
                <h3>Vuoi fare la spesa “per bene”?</h3>
                <p>
                    Ogni anno a ottobre, in collaborazione con alcune aziende e insegne della grande distribuzione, organizziamo
                    la campagna di promozione dei prodotti del commercio equo: le Settimane Fairtrade.
                </p>

                <h3>
                    Cosa si nasconde dietro il sottocosto
                </h3>
                <p>
                    Ti sei mai chiesto che cosa si nasconda dietro il “sottocosto”? Secondo te, chi paga quella
                    differenza che non viene messa in conto sullo scontrino? Sarà qualcun altro, probabilmente:
                    l’agricoltore, costretto a vendere i frutti del proprio lavoro ad un prezzo inferiore ai costi
                    di
                    produzione e a ridursi in povertà. O lo pagheremo tutti noi, prima o poi, sotto forma di effetti
                    sull’ambiente, di aumento delle migrazioni o di ingiustizia sociale.
                </p>

                <h3>
                    Spendere “per bene”.
                </h3>
                <p>
                    Ci sono invece dei soldi spesi per bene. Ad esempio quando compri prodotti che assicurano ad
                    agricoltori
                    e lavoratori migliori condizioni. Quando trovi il marchio Fairtrade le organizzazioni di
                    agricoltori
                    ricevono un Prezzo Minimo che copre i costi di una produzione sostenibile a livello economico e
                    ambientale; e un margine di guadagno aggiuntivo, il Premio Fairtrade, per avviare progetti a
                    vantaggio
                    della comunità.
                </p>
                <p>
                    Naturalmente centinaia di prodotti Fairtrade ti aspettano tutto l'anno in moltissimi
                    supermercati.
                </p>
                <p>
                    A ottobre cerca nel sito o nel volantino del supermercato in cui abitualmente fai la spesa: è
                    un’occasione per provarli, se non li hai mai acquistati prima, o per fare scorta dei tuoi prodotti
                    preferiti.
                </p>
                <p>
                    Puoi scegliere tra tanti: caffè, cacao e banane, zucchero ma anche biscotti, cereali per la
                    colazione,
                    gelati, frutta secca, succhi di frutta, prodotti da forno.
                </p>
                <p>
                    Con un’unica garanzia: Fairtrade.
                </p>
            </div>
        </div>
    )
}