import React from "react";
// import { useLocation } from "@reach/router"
import '../../styles/_typography.scss';
import './layout.scss';
import Headline from "../headline/headline";
import Navigation from "../navigation/navigation";
import Footer from "../footer/footer";
import arrow from '../../images/icons/arrow-down.svg';
// import CookieConsent from "react-cookie-consent";
// import {initializeAndTrack} from "gatsby-plugin-gdpr-cookies";

export default function Layout({children}) {
    return (
        <div className={'layout-container'}>
            <Navigation/>
            <Headline/>
            <div className={'skip-link'}><a href="#main-content"><img src={arrow} alt="Le settimane Fairtrade"/>prosegui</a></div>
            {children}
            <Footer/>
            {/*<CookieConsent*/}
            {/*    location="bottom"*/}
            {/*    buttonText="Accetto"*/}
            {/*    declineButtonText="Rifiuto"*/}
            {/*    cookieName="gatsby-gdpr-google-tagmanager"*/}
            {/*    onAccept={() => {*/}
            {/*        initializeAndTrack(location)*/}
            {/*    }}>*/}
            {/*    <div className={'cookie-consent'}>*/}
            {/*        <h3>Informativa</h3>*/}
            {/*        Noi e terze parti selezionate utilizziamo cookie o tecnologie simili come specificato nella cookie policy sul sito madre <a*/}
            {/*        href="https://www.fairtrade.it/" target={'_blank'}>https://www.fairtrade.it/</a>.<br/>*/}
            {/*        Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa, proseguendo la navigazione di questa pagina, interagendo con un link o un pulsante al di fuori di questa informativa o continuando a navigare in altro modo.*/}
            {/*    </div>*/}
            {/*</CookieConsent>*/}
        </div>
    )
}
