import React from "react";
import './teaser-round-image.scss';
import {StaticImage} from "gatsby-plugin-image";
import cart from "../../images/icons/cart.svg";

export default function TeaserRoundImage() {
    return (
        <section className={'teaser-round-image'} id={"main-content"}>
            <div className={'teaser-text'}>
                <h2>Cosa sono le Settimane Fairtrade?</h2>
                <p>
                    Ogni anno a ottobre, in collaborazione con alcune aziende e insegne della grande distribuzione, organizziamo
                    la campagna di promozione dei prodotti del <strong>commercio equo</strong>: le <strong>Settimane
                    Fairtrade</strong>.
                </p>
                <p>
                    Acquistando un prodotto Fairtrade <br/>
                    <strong>tante persone avranno condizioni più dignitose</strong>, <br/>
                    ricevendo un compenso adeguato per il loro lavoro.
                </p>
                <p>
                    Grazie alla tua scelta <strong>intere comunità potranno continuare a vivere</strong>.
                </p>
            </div>

            <div className={'teaser-image'}>
                <StaticImage
                    src="../../images/cosa-sono-le-settimane-fairtrade.png"
                    alt="Cosa sono le Settimane Fairtrade?"
                    placeholder="blurred"
                    width={800}
                />
                <div className={'overlay-cart-icon'}>
                    <img src={cart} alt="Fairtrade Italia - cart" width={'120'} height={'109'}/>
                </div>
            </div>
        </section>
    )
}