import React from "react";
import './sconto.scss';
import {StaticImage} from "gatsby-plugin-image";

export default function Sconto() {
    return (
        <section className={'sconto'}>
            <div className={'teaser-image-big'}>
                <StaticImage
                    src="../../images/fairtrade-sconto.jpg"
                    alt="Chi paga lo sconto?"
                    placeholder="blurred"
                    width={705}
                    height={470}
                />
            </div>

            <div className={'teaser-text'}>
                <h2>Chi paga lo sconto?</h2>
                <p>
                In occasione delle Settimane Fairtrade i prodotti certificati sono in offerta nei supermercati aderenti all’iniziativa. Ma i produttori agricoli e i lavoratori all' origine delle filiere non ci rimetteranno.
                </p>
                <p>
                Lo sconto, infatti, <strong>non è mai a scapito del Prezzo Minimo e del premio Fairtrade </strong>che viene pagato alle
organizzazioni di produttori. Quando scegliamo prodotti con il Marchio Fairtrade, questa è una garanzia
anche per noi consumatori.
                </p>
                <p>
                Le promozioni sui prodotti Fairtrade permettono a chi non li conosceva prima di provarli e a chi li acquista
regolarmente di farne scorta. Più prodotti Fairtrade saranno venduti, più guadagneranno gli agricoltori e i
lavoratori. Ed è questo ciò che per noi conta davvero!
                </p>
                <p>Partecipa anche tu alla nostra campagna e continua ad acquistarli anche durante l'anno!</p>
            </div>
        </section>
    )
}