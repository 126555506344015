import React from "react"
import Layout from "../components/layout/layout"
import TeaserRoundImage from "../components/teaser-round-image/teaser-round-image";
import Partners from "../components/partners/partners";
import Topics from "../components/topics/topics";
import Sconto from "../components/sconto/sconto";
import Newsletter from "../components/newsletter/newsletter";
import LastNews from "../components/last-news/last-news";

function IndexPage() {
    return (
        <Layout>
            <TeaserRoundImage/>
            <Partners/>
            <Topics/>
            <Sconto/>
            <section className={'two-column'}>
                <Newsletter/>
                <LastNews />
            </section>
        </Layout>
    );
}

export default IndexPage
