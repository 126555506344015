import React from "react";
import './topics.scss';
import {StaticImage} from "gatsby-plugin-image";
import arrowRight from "../../images/icons/arrow-right.svg";

export default function Topics() {
    return (
        <section className={'topics'}>
            <h2>Perché acquistare prodotti certificati Fairtrade?</h2>
            <div className={'topics-container'}>

                <div className={'topic'}>
                    <div className={'teaser-image'}>
                        <StaticImage
                            src="../../images/marchi-fairtrade.jpg"
                            alt="Il marchio nero"
                            placeholder="blurred"
                            width={592}
                            height={320}
                        />
                    </div>
                    <h3>IL MARCHIO</h3>
                    <p>
                        Quando vedi Fairtrade, sai che è la scelta migliore, per tutti: <strong>un marchio etico</strong>,
                        che caratterizza i prodotti realizzati rispettando l’<strong>ambiente</strong> e i <strong>diritti umani</strong>.
                    </p>
                    <p>
                        E questa scelta non è difficile, perché puoi trovarlo sugli scaffali del
                        supermercato, al bar, nelle mense scolastiche, nei ristoranti. Per riconoscere i
                        prodotti Fairtrade più facilmente, <a href={'ttps://www.fairtrade.it/marchi/'}  target={'_blank'}>qui trovi tutte le versioni del Marchio</a> e le informazioni che ti servono.
                    </p>
                    {/*<a href={'https://www.fairtrade.it/blog/news/verso-un-salario-dignitoso-per-i-coltivatori-di-banane/'} target={'_blank'}>*/}
                    {/*    leggi il nostro impegno*/}
                    {/*    <img src={arrowRight} alt="Il nostro impegno per un reddito dignitoso" />*/}
                    {/*</a>*/}
                </div>

                <div className={'topic'}>
                    <div className={'teaser-image'}>
                        <StaticImage
                            src="../../images/Prezzo-minimo-fairtrade.jpg"
                            alt="Il prezzo minimo"
                            placeholder="blurred"
                            width={592}
                            height={320}
                        />
                    </div>
                    <h3>IL PREZZO MINIMO</h3>
                    <p>
                        Ciò che mette più in difficoltà gli agricoltori in tutto il mondo è il crollo dei
                        prezzi dei loro prodotti.
                    </p>
                    <p>
                        Ecco perché, per ogni prodotto, Fairtrade ha stabilito
                        un <strong>prezzo minimo obbligatorio</strong>, calcolato insieme agli stessi produttori
                        agricoli in modo da coprire i costi necessari per una produzione sostenibile.
                        Quando il prezzo di mercato è più alto del Prezzo Minimo Fairtrade, agli
                        agricoltori viene pagato il prezzo di mercato.
                    </p>
                    {/*<a href={'https://www.fairtrade.it/blog/donne/il-potere-delle-donne-con-il-commercio-equo/'} target={'_blank'}>*/}
                    {/*    leggi il nostro impegno*/}
                    {/*    <img src={arrowRight} alt="Il nostro impegno per la parità di genere" />*/}
                    {/*</a>*/}
                </div>

                <div className={'topic'}>
                    <div className={'teaser-image'}>
                        <StaticImage
                            src="../../images/Premio-fairtrade.jpg"
                            alt="Il premio Fairtrade"
                            placeholder="blurred"
                            width={592}
                            height={320}
                        />
                    </div>
                    <h3>Il premio Fairtrade</h3>
                    <p>
                        È una <strong>somma di denaro pagata agli agricoltori in aggiunta</strong> al Prezzo Minimo.
                        Sono loro a decidere come spenderla: possono investire nell’azienda per
                        migliorare le tecniche produttive, finanziare strade, pozzi, scuole, ambulatori
                        per le loro comunità o anche ridistribuirla come integrazione al reddito.
                    </p>
                    {/*<a href={'https://www.fairtrade.it/blog/lavoro-minorile/lavoro-minorile-e-fairtrade-combattere-l-ingiustizia-e-costruire-futuro/'} target={'_blank'}>*/}
                    {/*    leggi il nostro impegno*/}
                    {/*    <img src={arrowRight} alt="Il nostro impegno per risolvere il problema del lavoro minorile" />*/}
                    {/*</a>*/}
                </div>

                <div className={'topic'}>
                    <div className={'teaser-image'}>
                        <StaticImage
                            src="../../images/fai-la-tua-parte.jpg"
                            alt="Fai la tua parte"
                            placeholder="blurred"
                            width={592}
                            height={320}
                        />
                    </div>
                    <h3>Fai la tua parte</h3>
                    <p>
                    Pensi anche tu che tutte le persone meritino una vita dignitosa? 
                    E che il commercio debba essere più giusto? 
                    Unisciti al movimento Fairtrade e lascia 
                    <a href={'https://action.fairtrade.org.uk/page/148566/petition/1'}  target={'_blank'}>la tua firma</a> per costruire insieme un futuro più sostenibile e in cui vengano rispettati i diritti dei produttori e dei lavoratori agricoli. 
                    </p>
                    {/*<a href={'https://www.fairtrade.it/blog/news/fairtrade-e-il-cambiamento-climatico-allarme-da-un-nuovo-studio/'} target={'_blank'}>*/}
                    {/*    leggi il nostro impegno*/}
                    {/*    <img src={arrowRight} alt="Il nostro impegno per risolvere la crisi climatica" />*/}
                    {/*</a>*/}
                </div>


                <p className={'topics-blog'}>Fairtrade fa molto altro ancora insieme agli agricoltori e ai lavoratori,
                    scoprilo nel nostro <a href={'https://www.fairtrade.it/blog/'} target={'_blank'}>BLOG</a>.</p>
            </div>

        </section>
    )
}